<template>
  <div>
    <RWCard title="News latest" :bodyFit="true">
      <template #body>
        <div v-if="loading.news" class="mx-5 my-5">
          <v-skeleton-loader type="paragraph@3"></v-skeleton-loader>
        </div>
        <div v-else>
          <b-list-group :flush="true" v-if="success">
            <b-list-group-item
              :to="{ name: 'news-single', params: { id: row.id } }"
              class="flex-column align-items-start"
              v-for="(row, key) in xhr.news.record"
              :key="key"
            >
              <div class="d-flex w-100 justify-content-between">
                <h5 class="mb-1">{{ row.title }}</h5>
              </div>

              <p class="mb-1">
                {{ row.announcement }}
              </p>

              <small class="text-muted"
                ><i class="fa fa-calendar"></i> {{ row.tanggal }}</small
              >
            </b-list-group-item>
          </b-list-group>
          <b-alert show variant="danger" class="m-4" v-else>{{
            xhr.news.message
          }}</b-alert>
        </div>
      </template>
      <template #foot>
        <div class="text-center" v-if="success">
          <!-- pagination -->
          <b-pagination
            v-model="pagination.current"
            :total-rows="pagination.allCount"
            :per-page="pagination.limit"
            @change="handlePagination"
            align="right"
            first-text="First"
            prev-text="Prev"
            next-text="Next"
            last-text="Last"
          ></b-pagination>
        </div>
      </template>
    </RWCard>
  </div>
</template>

<script>
import RWCard from "@/views/partials/content/Portlet.vue";
import { breadcrumb, ApiURL, util } from "@/common/mixins/general.js";
export default {
  mixins: [breadcrumb, ApiURL, util],
  components: { RWCard },
  data() {
    return {
      loading: {
        news: true
      },
      xhr: {},
      pagination: {
        limit: 5,
        offset: 0,
        current: 1,
        allCount: 0
      },
      success: false
    };
  },
  methods: {
    get_all_news() {
      let query = this.mx_ObjectToQuery(this.query_request, "?");
      this.xhr_news("index", query)
        .then(({ data }) => {
          this.xhr.news = data.data.news;
          this.pagination.allCount = data.data.news.count;
          this.success = true;
        })
        .catch(({ response }) => {
          this.xhr.news = response.data;
        })
        .finally(() => {
          this.loading.news = false;
        });
    },

    /**
     * ------------------------------------------------
     * handle pagination next/prev
     * ------------------------------------------------
     */
    handlePagination: function(page) {
      this.loading.news = true;
      this.pagination.offset = (page - 1) * this.pagination.limit;

      this.get_all_news();
    }
  },
  computed: {
    /**
     * -------------------------------------------------
     * generate query request data
     * -------------------------------------------------
     */
    query_request: function() {
      let query = new Object();
      query.limit = this.pagination.limit;
      query.offset = this.pagination.offset;
      return query;
    }
  },
  mounted() {
    this.set_breadcrumb([{ title: "News", route: "news" }]);

    this.get_all_news();
  }
};
</script>
